import React, { Fragment, Component } from "react";

import PropTypes from "prop-types";

import {
  Col,
  FormGroup,
  Label,
  PopoverBody,
  UncontrolledPopover,
  Progress,
} from "reactstrap";

import RegistrationService from "../../Services/RegistrationService";

import PasswordInput from "./PasswordInput";

export default class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPassword: null,
      validInitPass: null,
      initPassDomId: "initialPassword",
      initPassError: "Your password is almost strong enough!",
      finalPassword: null,
      validFinalPass: null,
      finalPassDomId: "finalPassword",
      finalPassError: "This must match your initial password!",
      passwordStrength: 0,
    };

    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState((state) => {
        return { initPassError: this.props.error, validInitPass: false };
      });
    }
  }

  /**
   * Entrypoint for validating password values and entering them into component state. Calls helper
   * functions to validate based on which password we're validating
   * @param {Object} event An object containing the changed password and the DOM element generating the change:
   * {
   *    name: <DOM Element ID>
   *    value: <password change>
   * }
   */
  updatePassword(event) {
    const domId = event.name;
    const password = event.value;

    this.setState(
      (state) => {
        let stateCopy = JSON.parse(JSON.stringify(state));
        // Copy the object tracking validity values and mututate the attribute we care about mutating
        if (domId === stateCopy.initPassDomId) {
          stateCopy.validInitPass =
            RegistrationService.validateInitialPassword(password);
          stateCopy.validFinalPass =
            stateCopy.confirmPassword != null &&
            RegistrationService.validateConfirmPassword(
              password,
              stateCopy.confirmPassword,
            );
          // We only change the password strength when the initial password is being changed
          stateCopy.passwordStrength =
            RegistrationService.getPasswordStrength(password);
          stateCopy.initPassError = "Your password is almost strong enough!";
          stateCopy.initialPassword = password;
        } else {
          stateCopy.validFinalPass =
            RegistrationService.validateConfirmPassword(
              stateCopy.initialPassword,
              password,
            );
          stateCopy.finalPassword = password;

          // If the confirmation password is invalid and the intial password is valid, then the
          // main issue is mismatching password fields
          if (!stateCopy.validFinalPass && stateCopy.validInitPass) {
            stateCopy.finalPassError = "This must match your initial password!";
          }
          // If the confirmation password is invalid and the inital password is invalid, then
          // the main issue is a weak initial password
          else if (!stateCopy.validFinalPass && !stateCopy.validInitPass) {
            stateCopy.finalPassError =
              "Your initial password isn't strong enough!";
          }
        }

        // Set the state
        return stateCopy;
      },
      () => {
        this.props.passwordCallback(
          this.state.initialPassword,
          this.state.validInitPass && this.state.validFinalPass,
        );
      },
    );
  }

  /**
   *
   * @returns Password popover bound to the initial password DOM element
   */
  generatePasswordPopover() {
    let validOpts = Object.assign(RegistrationService.validOpts);
    let domElementId = "passwordProgress";

    return (
      <UncontrolledPopover
        trigger="focus"
        placement="right"
        target={this.state.initPassDomId}
      >
        <PopoverBody>
          <ul>
            <li>At least {validOpts.minLength} characters long</li>
            <li>At most {validOpts.maxLength} characters long</li>
            <li>At least {validOpts.minLowercase} lowercase characters</li>
            <li>At least {validOpts.minUppercase} uppercase characters</li>
            <li>At least {validOpts.minNumbers} digits</li>
            <li>
              At least {validOpts.minSymbols} symbols from the following set:
            </li>
            <ul>
              <li>{validOpts.symbols}</li>
            </ul>
          </ul>
          <div>
            <Label for={domElementId}>Password Strength:</Label>
            <Progress
              id={domElementId}
              color={this.state.validInitPass ? "success" : "danger"}
              value={this.state.passwordStrength}
              striped
              max={validOpts.pointsForStrongPassword}
            />
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    );
  }

  render() {
    return (
      <Fragment>
        <Col md={this.props.columnWidth ? this.props.columnWidth : 6}>
          <FormGroup>
            <PasswordInput
              domElementId={this.state.initPassDomId}
              handlePasswordChange={this.updatePassword}
              label="Password"
              showValidity
              isValidPassword={this.state.validInitPass}
              errorMessage={this.state.initPassError}
            />
            {this.generatePasswordPopover()}
          </FormGroup>
        </Col>
        <Col md={this.props.columnWidth ? this.props.columnWidth : 6}>
          <FormGroup>
            <PasswordInput
              domElementId={this.state.finalPassDomId}
              handlePasswordChange={this.updatePassword}
              label="Repeat Password"
              showValidity
              isValidPassword={this.state.validFinalPass}
              errorMessage={this.state.finalPassError}
            />
          </FormGroup>
        </Col>
      </Fragment>
    );
  }
}

CreatePassword.propTypes = {
  passwordCallback: PropTypes.func.isRequired,
  error: PropTypes.string,
  //Optional width of the input boxes, measured in bootstrap columns: 1 <= columnWidth <= 12
  columnWidth: PropTypes.number,
};
