/**
 * Main entry point for the Mathsolutely front end
 */

//This is a weird import of a file that just imports another file
import { HashRouter } from "react-router-dom";

//Third Party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Theme
import "./polyfills";
import * as serviceWorker from "./serviceWorker";
import "./assets/base.scss";
import configureStore from "./config/configureStore";

//Mathsolutely
import Main from "./Mathsolutely/Main";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement,
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Mathsolutely/Main", () => {
    const NextApp = require("./Mathsolutely/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.register();
//serviceWorker.unregister();
