import React, { Component } from "react";

import PropTypes from "prop-types";

import CreatePassword from "./CreatePassword";
import PasswordInput from "./PasswordInput";
import UpdatePassword from "./UpdatePassword";

export default class PasswordInputPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // If we're logging in, we need to use this part of state
      passwordLogin: {
        password: null,
        loginPasswordRef: React.createRef(),
        passDomId: "loginPassword",
        loginErrorMessages: null,
      },
    };
  }

  generatePanelContents() {
    if (this.props.mode === "create") {
      return (
        <CreatePassword
          passwordCallback={this.props.passwordCallback}
          error={this.props.error}
          columnWidth={this.props.columnWidth}
        />
      );
    } else if (this.props.mode === "login") {
      return (
        <PasswordInput
          domElementId="password"
          handlePasswordChange={this.props.passwordCallback}
          label="Password"
          showValidity={false}
          isValidPassword={this.props.isValidPassword}
          errorMessage={this.props.error}
          columnWidth={this.props.columnWidth}
        />
      );
    } else if (this.props.mode === "update") {
      return (
        <UpdatePassword
          passwordCallback={this.props.passwordCallback}
          error={this.props.error}
          columnWidth={this.props.columnWidth}
        />
      );
    }
  }

  render() {
    return this.generatePanelContents();
  }
}

PasswordInputPanel.propTypes = {
  mode: PropTypes.oneOf(["create", "login", "reset", "update"]),
  // Callback required to get password data out of the component
  passwordCallback: PropTypes.func.isRequired,
  // Any error that should be propagated to returned child component
  error: PropTypes.string,
  isValidPassword: PropTypes.bool,
  //Optional width of the input boxes, measured in bootstrap columns: 1 <= columnWidth <= 12
  columnWidth: PropTypes.number,
};
