import { axiosInstance } from "Mathsolutely/Util/api";
import validator from "validator";

/**
 * This client-side service's purpose is to prepare and send off registration requests to the server
 * It will:
 * - Prepare data for transmission to server
 * - Transmit data to server
 */

const RegistrationService = {
  // Note maxLength is a Mathsolutely option, it is not recognized by the validator we use
  // Note symbols is a Mathsolutely option, it is not recognized by the validator we use
  // Note illegalSymbols is a Mathsolutely option, it is not recognized by the validator we use
  // Note pointsForStrongPassword is a Mathsolutely option, it is not recognized by the validator we use
  validOpts: {
    // The current list of configured password symbols. This is currently the same as those in the
    // employed version of validator.js, except for the space char. The space char was removed because
    // it is hard to display that as a valid char to the user
    // Note the current version of validator.js does not treat @ as a symbol, this has been fixed in later versions
    symbols: "-#!$%^&*()_+|~=`{}[]:\";'<>?\\,./",
    // A regex representing symbols that are not allowed in passwords
    illegalSymbols: " ",
    minLength: 12,
    maxLength: 32,
    minLowercase: 2,
    minUppercase: 2,
    minNumbers: 2,
    minSymbols: 2,
    returnScore: false,
    pointsPerUnique: 1,
    pointsPerRepeat: 0.5,
    pointsForContainingLower: 10,
    pointsForContainingUpper: 10,
    pointsForContainingNumber: 10,
    pointsForContainingSymbol: 10,
    pointsForStrongPassword: 50,
  },
  /**
   * Resends the confirmation email if the user requests it
   * @param {String} userId The user's Mongo object ID
   */
  resendConfirmationEmail: async function (userId) {
    const options = {
      method: "get",
      url: "/register/resend/" + userId,
    };

    try {
      await axiosInstance(options);
    } catch (error) {
      if (error.response != null) {
        // The server returned a non-2xx/3xx code
        // What to do?
        console.log("The request failed");
        console.log(error);
      } else if (error.request != null) {
        // We couldn't talk to the server
        console.log("We couldn't connect to the server");
      }
    }
  },

  /**
   * Sends the registration request to the server.
   * Returns true if the server accepted the registration request, returns false otherwise
   * @param {Object} registrationData
   * @returns An object containing at a minimum a boolean where true indicates successful
   *          registration, false otherwise
   */
  sendRegistrationRequest: async function (registrationData, role) {
    let registrationResponse = {
      success: false,
    };

    const options = {
      method: "post",
      url:
        "" +
        (registrationData.purgatoryId
          ? "/register/purgatory/ascend"
          : "/register"),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: this.prepareRegistrationRequestData(registrationData, role),
    };

    let response = null;
    try {
      // what to do?
      response = await axiosInstance(options);
      registrationResponse.success = true;
      registrationResponse.response = Object.assign(response);
    } catch (error) {
      if (error.response != null) {
        // The server returned a non-2xx/3xx code
        registrationResponse.success = false;
        if (error.response.data.hasOwnProperty("errors")) {
          registrationResponse.response = this.processErrorMessages(
            error.response.data.errors,
          );
        } else if (error.response.data.hasOwnProperty("failureCause")) {
          registrationResponse.failureCause = error.response.data.failureCause;
          registrationResponse.response = error.response;
        }
      } else if (error.request != null) {
        // Some sort of connection issue to the server occurred, which is why we're here
        registrationResponse.request = error.request;
        registrationResponse.success = false;
        registrationResponse.failureCause =
          "Oh no! We couldn't connect to the server. Please try again!";
        // TODO something at least a little clever
      }
    }

    return registrationResponse;
  },

  /**
   * This function takes a JSON object containing registration data and packages it into a shape
   * the server expects. We also validate the data. We return this transformed data. This is the
   * expected shape of the data for purgatory ascension:
   * {
   *      firstName:    String
   *      lastName:     String
   *      email:        String
   *      password:     String
   * }
   *
   * This is the expected shape of the data for user registration:
   * {
   *      user: {
   *          firstName:    String
   *          lastName:     String
   *          email:        String
   *          password:     String
   *      }
   *      role: String
   * }
   * @param {Object} registrationData
   */
  prepareRegistrationRequestData: function (registrationData, role) {
    registrationData.email = registrationData.email.toLowerCase();

    return {
      user: registrationData,
      role: role || "STUDENT",
    };
    // TODO anything to actually do here? The data entering already is of the correct shape
  },

  /**
   * Validates that the initPass is a strong password as specified by our validator config options.
   * The default is:
   * {
   *    minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,
   *    returnScore: false, pointsPerUnique: 1, pointsPerRepeat: 0.5,
   *    pointsForContainingLower: 10, pointsForContainingUpper: 10,
   *    pointsForContainingNumber: 10, pointsForContainingSymbol: 10
   * }
   * @param {String} initPass The password string
   */
  validateInitialPassword: function (initPass) {
    // Right now, the String#includes check for a space char is a hack to get this bit working.
    // TODO improve with regex check for illegal symbols in future
    return (
      validator.isStrongPassword(initPass, this.validOpts) &&
      !initPass.includes(" ") &&
      initPass.length <= this.validOpts.maxLength
    );
  },

  /**
   * Validates that the confirmPass is the same as the initial password
   * @param {String} initPass The initial password
   * @param {String} confirmPass The confirmation password
   */
  validateConfirmPassword: function (initPass, confirmPass) {
    return (
      initPass != null &&
      this.validateInitialPassword(initPass) &&
      initPass === confirmPass
    );
  },

  getPasswordStrength: function (password) {
    let passwordOptions = JSON.parse(JSON.stringify(this.validOpts));
    passwordOptions.returnScore = true;

    return validator.isStrongPassword(password, passwordOptions);
  },

  /**
   * Takes error objects from the server, processes them, and returns an array containing simplified
   * error objects for the registration component to use. The returned error objects will be
   * structured as follows:
   * {
   *    fieldName   : String   Name of the invalid thing. Component will need to map this appropriately
   *    errorMessage: String   Error message for the thing. Component will need to set this as form feedback
   * }
   * @param {Array<{value: any, msg: string, param: string, location: string}>} serverErrorArray
   * @returns {Array<{field: string, errorMessage: string}>}An array of error objects
   */
  processErrorMessages: function (serverErrorArray) {
    return serverErrorArray.map((error) => {
      return {
        field: error.param,
        errorMessage: error.msg,
      };
    });
  },

  /**
   * Given the user's token, fire the confirmation and
   * @param {String} userToken
   */
  confirmUser: async function (userToken) {
    const options = {
      method: "get",
      url: "/register/confirm/" + userToken,
    };

    let confirmationSuccess = false;
    try {
      await axiosInstance(options);
      // If the request returns successfully, then the user was successfully confirmed
      confirmationSuccess = true;
    } catch (error) {
      // Do something clever
    }

    return confirmationSuccess;
  },

  /**
   * Retrieves any potential data that can be used to pre-fill forms for purgatory users working on completing their
   * registration.
   * @param purgatoryId The purgatory ID retrieved from URL query params.
   * @return {Promise<Object>} Upon resolution, will contain an object with form prefill data that looks something
   * like this:
   * {
   *   emailAddress: String
   * }
   */
  getPurgatoryInfo: async function (purgatoryId) {
    const options = {
      method: "get",
      url: "/register/purgatory/user/" + purgatoryId,
    };

    let result = null;
    try {
      result = (await axiosInstance(options)).data;
    } catch (error) {
      // Do something clever
    }

    return result;
  },
};

export default RegistrationService;
