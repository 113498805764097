/**
 * Main application content, loads authorized and entry content, with fallbacks
 * for when data is loading from the server
 */

//Third Party
import CookieConsent from "react-cookie-consent";
import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useState, useEffect } from "react";
import Loader from "react-loaders";
import MathJax from "mathjax3-react";

//Mathsolutely
import config from "../config";
import AuthenticationService from "../Services/AuthenticationService";
import { registerAuthFailListener } from "../Util/api";
import SessionExpiredDialog from "../Components/SessionExpiredDialog";

//Lazy load of content
const Entry = lazy(() => import("../Entry"));
const Authorized = lazy(() => import("../Authorized"));

/**
 * Generic "application is loading" display with animated loading icon
 */
function LoadingContent() {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="line-scale" />
        </div>
        <h6 className="mt-5">
          Please wait while we load Mathsolutely
          <small>
            Loading shouldn't take long. But, if you are reading this, welcome
            and good luck!
          </small>
        </h6>
      </div>
    </div>
  );
}

/**
 * Main application, loading content and displaying fallback loading content.  Redirect to the login page.
 */
const AppMain = function () {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReauth, setIsReauth] = useState(false);
  const [isForceRedirect, setIsForceRedirect] = useState(false);
  const [authPromiseFuncs, setAuthPromiseFuncs] = useState({});

  const refreshAuthFunction = () => {
    return new Promise((resolve, reject) => {
      setAuthPromiseFuncs({ resolve, reject });
      setIsReauth(true);
    })
      .then(() => {
        setIsReauth(false);
        setAuthPromiseFuncs(null);
      })
      .catch(() => {
        // Something weird happened. Kick the user out of the website.
        setIsForceRedirect(true);
      });
  };

  useEffect(() => {
    registerAuthFailListener(refreshAuthFunction);
  }, []);

  useEffect(() => {
    AuthenticationService.loggedIn().then((loggedInValue) => {
      setIsLoggedIn(loggedInValue);
    });
  }, []);

  if (isForceRedirect) {
    return <Redirect to={"/entry/login"} />;
  }

  return (
    <Fragment>
      <MathJax.Provider options={config.mathjaxOptions}>
        {/*Authenticated & Authorized Content*/}
        <Suspense fallback={<LoadingContent />}>
          {/* TODO pass isLoggedIn to the Authorized */}
          <Route path="/authorized" component={Authorized} />
        </Suspense>

        {/* Entry Content */}
        <Suspense fallback={<LoadingContent />}>
          <Route
            path="/entry"
            render={(props) => <Entry {...props} isLoggedIn={isLoggedIn} />}
          />
        </Suspense>

        {/* Default redirect to the login page */}
        <Route exact path="/" render={() => <Redirect to="/entry/landing" />} />
      </MathJax.Provider>
      {isReauth ? (
        <SessionExpiredDialog
          reauthSuccess={authPromiseFuncs.resolve}
          reauthFailure={authPromiseFuncs.reject}
        />
      ) : null}
      <CookieConsent
        cookieName="necessaryCookieConsent"
        cookieValue={"accepted"}
        style={{ zIndex: 1010 }}
        buttonStyle={{
          background: "#E8743A",
          color: "white",
          font: "13px",
          borderRadius: "5px",
        }}
        expires={365}
      >
        We use only strictly-necessary cookies on Mathsolutely (which allows the
        site to function properly). User personal information is not stored as
        cookies. We just wanted to let you know.{" "}
        <a href="/#/entry/about/privacy-policy" target="_blank">
          More Information
        </a>
      </CookieConsent>
    </Fragment>
  );
};

export default AppMain;
