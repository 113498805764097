/**
 * Main Component for the Mathsolutely application.  Sets up screen layout, color theme, etc.
 */

//Third Party
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";

//Mathsolutely
import AppMain from "../AppMain";

/**
 * Main Component.  Adjusts screen layout based on window size
 */
class Main extends React.Component {
  render() {
    // It's unclear how necessary this layer/component is, at this point
    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <AppMain />
          </Fragment>
        )}
      />
    );
  }
}

export default withRouter(Main);
