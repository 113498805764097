import React, { Fragment, Component } from "react";

import {
  FormFeedback,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import PropTypes from "prop-types";

import RegistrationService from "../../Services/RegistrationService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      showPassword: false,
    };

    this.togglePasswordReveal = this.togglePasswordReveal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Toggles the bool controlling whether or not the password is revealed
   */
  togglePasswordReveal() {
    this.setState((state) => {
      return { showPassword: !state.showPassword };
    });
  }

  /**
   * Handles changes from user interaction via the provided callback
   * @param {Object} event The change event
   */
  handleChange(event) {
    const password = String(event.target.value);
    this.setState(
      (state) => {
        return { password: password };
      },
      () => {
        if (this.props.handlePasswordChange) {
          this.props.handlePasswordChange({
            name: this.props.domElementId,
            value: password,
          });
        }
      },
    );
  }

  render() {
    return (
      <Fragment>
        <Label for={this.props.domElementId}>
          <span className="text-danger">*</span> {this.props.label}
        </Label>
        <InputGroup>
          <Input
            type={this.state.showPassword ? "text" : "password"}
            name={this.props.domElementId}
            id={this.props.domElementId}
            placeholder="Enter your password"
            maxLength={RegistrationService.validOpts.maxLength}
            onChange={(event) => this.handleChange(event)}
            valid={
              this.props.showValidity &&
              this.state.password != null &&
              this.props.isValidPassword
            }
            invalid={
              this.props.showValidity &&
              this.state.password != null &&
              !this.props.isValidPassword
            }
          />
          <InputGroupAddon addonType="append">
            <Button
              id={"revealPassword" + this.props.domElementId}
              onClick={this.togglePasswordReveal}
            >
              <span>
                {this.state.showPassword ? (
                  <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                )}
              </span>
            </Button>
          </InputGroupAddon>
          <FormFeedback
            style={{
              display: "block",
              visibility:
                this.state.password != null && !this.props.isValidPassword
                  ? "visible"
                  : "hidden",
            }}
          >
            {this.props.errorMessage}
          </FormFeedback>
        </InputGroup>
      </Fragment>
    );
  }
}

PasswordInput.propTypes = {
  // The DOM ID this component will use to facilitate passing data with parent
  domElementId: PropTypes.string.isRequired,
  // Used to update the parent on password changes when creating password. Optional by default
  handlePasswordChange: PropTypes.func,
  // The text for the Label tag
  label: PropTypes.string.isRequired,
  // True if we want the Input component to display field validity, false otherwise
  showValidity: PropTypes.bool.isRequired,
  // If showValidity is true, then this must be passed so field validity can be shown
  isValidPassword: PropTypes.bool,
  // If the password has an error messages, set this prop
  errorMessage: PropTypes.string,
};
