//Reducer for global state data pertaining to test taking
//Variables/state described in comments on the setter definitions below

export const SET_TEST_IN_PROGRESS = "TEST/TEST_IN_PROGRESS";
export const SET_TEST_PROGRESS = "TEST/TEST_PROGRESS";
export const SET_TEST = "TEST/TEST";
export const SET_TEST_RESULTS = "TEST/TEST_RESULTS";
export const SET_TEST_ANSWER_NOTES = "TEST/TEST_ANSWER";
export const SET_TEST_STARTED = "TEST/TEST_STARTED";
export const SET_TEST_CLEAR = "TEST/TEST_CLEAR";
export const SET_TEST_TIMEOUT = "TEST/TEST_TIMEOUT";
export const SET_TEST_CROSS_OUT = "TEST/CROSS-OUT";
export const SET_TEST_BOOKMARK = "TEST/BOOKMARK";
export const SET_TEST_ANSWERED = "TEST/ANSWERED";
export const SET_TEST_MODULE_CHANGE = "TEST/MODULE_CHANGE";
export const SET_TEST_START_DATE = "TEST/START_DATE";
export const SET_LOADING_ERROR = "TEST/SET_LOADING_ERROR";
export const REPLACE_BOOKMARK = "TEST/REPLACE_BOOKMARK";
export const REPLACE_CROSS_OUT = "TEST/REPLACE_CROSS_OUT";
export const SET_MODULE_TIMEOUT = "TEST/MODULE_TIMEOUT";

/**
 * Single boolean value indicating if the first module timer has expired.
 */
export const setModuleTimeout = (moduleTimeout) => ({
  type: SET_MODULE_TIMEOUT,
  moduleTimeout,
});

/**
 * Single boolean value indicating if the test timer has expired.
 */
export const setTestTimeout = (testTimeout) => ({
  type: SET_TEST_TIMEOUT,
  testTimeout,
});

/**
 * Start date of the test
 */
export const setTestStartDate = (testStartDate) => ({
  type: SET_TEST_START_DATE,
  testStartDate,
});

/**
 * Start date of the test
 */
export const setLoadingError = (loadingError) => ({
  type: SET_LOADING_ERROR,
  loadingError,
});

/**
 * Single boolean value indicating if a test is in progress.
 */
export const setTestInProgress = (testInProgress) => ({
  type: SET_TEST_IN_PROGRESS,
  testInProgress,
});

/**
 * Test Progress object, used to keep track of test progress during the test-taking
 * process.  Stores data like number of questions answered, percent compmlete, etc.
 *
 */
export const setTestProgress = (testProgress) => ({
  type: SET_TEST_PROGRESS,
  testProgress,
});

/**
 * The actual test being administered.  Correlates to a Test object per database schema.
 */
export const setTest = (test) => ({
  type: SET_TEST,
  test,
});

/**
 * The test results of the test being taken.  Correlates to a TestResult object per database schema.
 */
export const setTestResults = (testResults) => ({
  type: SET_TEST_RESULTS,
  testResults,
});

/**
 * Test notes of a test answer.  Correlates to a TestAnswer object per database schema.
 */
export const setTestAnswerNotes = (id, index, notes) => ({
  type: SET_TEST_ANSWER_NOTES,
  payload: { id: id, index, notes: notes },
});

/**
 * Boolean to indicate if a test is started, used to trigger timers
 */
export const setTestStarted = (testStarted) => ({
  type: SET_TEST_STARTED,
  testStarted,
});

export const setTestClear = (testClear) => ({
  type: SET_TEST_CLEAR,
  testClear,
});

export const setAnswered = (key, value) => ({
  type: SET_TEST_ANSWERED,
  payload: { key: key, value: value },
});

export const setCrossedOut = (key, value) => ({
  type: SET_TEST_CROSS_OUT,
  payload: { key: key, value: value },
});

export const setTestModuleChange = (testModuleChange) => ({
  type: SET_TEST_MODULE_CHANGE,
  testModuleChange,
});

export const replaceBookmarkMap = () => ({
  type: REPLACE_BOOKMARK,
});

export const replaceCrossOutMap = () => ({
  type: REPLACE_CROSS_OUT,
});

/**
 * Stores a map in local storage
 * @param {string} key The location to store the map
 * @param {Map<any>} map The map to store in local storage
 */
const setLocalStorageMap = (key, map) => {
  localStorage.setItem(key, JSON.stringify(Array.from(map.entries())));
};

/**
 * Restores a map from local storage.
 *
 * NOTE: This assumes the item in local storage is a map.
 * @param {string} key The location of the map to restore
 */
export const retrieveLocalStorageMap = (key) => {
  if (localStorage[key]) {
    return new Map(JSON.parse(localStorage[key]));
  }

  return new Map();
};

/**
 * Removes the maps the store notes, crossed out answers, and bookmarks from
 * the user's local storage
 */
export const removeTestMaps = () => {
  localStorage.removeItem("crossedOutMap");
  localStorage.removeItem("bookmarkedProblems");
  localStorage.removeItem("notes");
};

/**
 * Updates the notes map in local storage
 * @param {*} index - The index of the problem to add the note to
 * @param {*} note - The problem note
 */
const editNoteMap = (index, note) => {
  const notes = retrieveLocalStorageMap("notes");
  notes.set(index, note);

  setLocalStorageMap("notes", notes);
};

export default function reducer(
  state = {
    testInProgress: false,
    test: false,
    testResults: false,
    testProgress: false,
    testStarted: false,
    testClear: false,
    testTimeout: false,
    crossedOutMap: new Map(),
    bookmarkedProblems: new Map(),
    answeredMap: new Map(),
    startDate: null,
    loadingError: null,
    moduleTimeout: false,
  },
  action,
) {
  switch (action.type) {
    case SET_TEST_CLEAR:
      removeTestMaps();

      return {
        testInProgress: false,
        test: false,
        testResults: false,
        testProgress: false,
        testStarted: false,
        crossedOutMap: new Map(),
        bookmarkedProblems: new Map(),
        answeredMap: new Map(),
        testClear: action.testClear,
        moduleTimeout: false,
      };
    case SET_TEST_MODULE_CHANGE:
      removeTestMaps();

      return {
        ...state,
        bookmarkedProblems: new Map(),
        crossedOutMap: new Map(),
        answeredMap: new Map(),
      };
    case SET_TEST_IN_PROGRESS:
      return {
        ...state,
        testInProgress: action.testInProgress,
      };
    case SET_TEST_PROGRESS:
      return {
        ...state,
        testProgress: action.testProgress,
      };
    case SET_TEST:
      return {
        ...state,
        test: action.test,
      };
    case SET_TEST_RESULTS:
      return {
        ...state,
        testResults: action.testResults,
      };
    case SET_TEST_ANSWER_NOTES:
      editNoteMap(action.payload.index, action.payload.notes);

      if (state.testProgress.currentModule === 1) {
        //Update notes on first module answers
        return {
          ...state,
          testResults: {
            ...state.testResults,
            testAnswers: state.testResults.testAnswers.map((result, _) =>
              result._id === action.payload.id
                ? { ...result, notes: action.payload.notes }
                : result,
            ),
          },
        };
      } else if (state.testProgress.currentModule === 2) {
        //Update notes on second module answers
        return {
          ...state,
          testResults: {
            ...state.testResults,
            secondModuleAnswers: state.testResults.secondModuleAnswers.map(
              (result, _) =>
                result._id === action.payload.id
                  ? { ...result, notes: action.payload.notes }
                  : result,
            ),
          },
        };
      }
    case SET_TEST_STARTED:
      return {
        ...state,
        testStarted: action.testStarted,
      };
    case SET_TEST_TIMEOUT:
      return {
        ...state,
        testTimeout: action.testTimeout,
      };
    case SET_MODULE_TIMEOUT:
      return {
        ...state,
        moduleTimeout: action.moduleTimeout,
      };
    case SET_TEST_CROSS_OUT:
      let newMap = state.crossedOutMap || new Map();
      newMap.set(action.payload.key, action.payload.value);

      const crossedOutMap = new Map(newMap);

      setLocalStorageMap("crossedOutMap", crossedOutMap);

      return {
        ...state,
        crossedOutMap: crossedOutMap,
      };
    case REPLACE_CROSS_OUT:
      return {
        ...state,
        crossedOutMap: retrieveLocalStorageMap("crossedOutMap"),
      };
    case SET_TEST_BOOKMARK:
      let newBookmarkMap = state.bookmarkedProblems || new Map();
      newBookmarkMap.set(action.payload.key, action.payload.value);

      const bookmarkMap = new Map(newBookmarkMap);

      setLocalStorageMap("bookmarkedProblems", bookmarkMap);

      return {
        ...state,
        bookmarkedProblems: bookmarkMap,
      };
    case REPLACE_BOOKMARK:
      return {
        ...state,
        bookmarkedProblems: retrieveLocalStorageMap("bookmarkedProblems"),
      };
    case SET_TEST_ANSWERED:
      let newAnsweredMap = state.answeredMap || new Map();
      newAnsweredMap.set(action.payload.key, action.payload.value);

      return {
        ...state,
        answeredMap: new Map(newAnsweredMap),
      };
    case SET_TEST_START_DATE:
      return {
        ...state,
        startDate: action.testStartDate,
      };
    case SET_LOADING_ERROR:
      return {
        ...state,
        loadingError: action.loadingError,
      };
    default:
  }

  return state;
}
