import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import PropTypes from "prop-types";

import AuthenticationService from "Mathsolutely/Services/AuthenticationService";
import PasswordInputPanel from "../PasswordInputPanel";
import "./session-expired.css";

/**
 * This component renders a non-dismissable, uncloseable modal prompting the user to enter their password to
 * reauthenticate. The user will not be prompted for their email address as that will be drawn from data stored in
 * session storage. The component will signal successful/failed authentication via functions passed as props.
 * @param { function } reauthSuccess This function will be called when the user successfullyy reauthenticates.
 * @param { function } reauthFailure This function will be called when the user fails to reauthenticate, e.g. cancels.
 * @return {JSX.Element} The modal.
 * @constructor
 */
export default function SessionExpiredDialog({ reauthSuccess, reauthFailure }) {
  const [password, setPassword] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(null);

  const email = AuthenticationService.getUser()
    ? AuthenticationService.getUser().email
    : null;

  const onSubmit = (event) => {
    event.preventDefault();
    AuthenticationService.authenticateUser(email, password.value)
      .then(({ success }) => {
        if (success) {
          setIsValidPassword(true);
          setAuthError(null);
          reauthSuccess();
        } else {
          setAuthError("Password incorrect. Please try again.");
          setIsValidPassword(false);
        }
      })
      .catch(() => {
        setAuthError(
          "An unexpected error occurred. You will be logged out momentarily",
        );
        setIsValidPassword(false);
        setTimeout(() => {
          reauthFailure();
        }, 2000);
      });
  };

  const onCancel = () => {
    AuthenticationService.logout();
    reauthFailure();
  };

  return (
    <Modal
      isOpen={true}
      backdrop="static"
      fullscreen="true"
      size="lg"
      className={"w-100"}
    >
      <ModalHeader>Session Expired</ModalHeader>
      <ModalBody>
        <Row className={"mb-3"}>
          <Col>
            Your Mathsolutely session has expired. To continue studying, please
            re-enter your password and login.
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={onSubmit}>
              <Row form>
                <Col>
                  <FormGroup>
                    <PasswordInputPanel
                      passwordCallback={setPassword}
                      mode={"login"}
                      error={authError}
                      isValidPassword={isValidPassword}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"mt-2"}>
                <Col className={"button-col"}>
                  <Button
                    id={"relogin-button"}
                    outline
                    color="primary"
                    type={"submit"}
                    className={"mr-1"}
                    disabled={!(password && password.value)}
                  >
                    Login
                  </Button>
                  <Button id="cancel" outline color="danger" onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

SessionExpiredDialog.propTypes = {
  /**
   * This function will be called to signal successful reauthentication.
   */
  reauthSuccess: PropTypes.func.isRequired,
  /**
   * This function will be called to signal failed reauthentication, e.g. if the user chooses not to reauth.
   */
  reauthFailure: PropTypes.func.isRequired,
};
